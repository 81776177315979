<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="col-12 auth-logo">
          <img src="@/assets/images/logo-full.png" />
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
